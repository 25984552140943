<template>
  <div class="healthHouse-menu">
    <div class="healthHouse-menu-t"><img src="./img/back.png" alt="" /></div>
    <div class="healthHouse-menu-b">
      <div
        class="item"
        v-for="(item, index) in oData"
        @click="toDetail(item)"
        :key="index"
      >
        <div class="item-l">{{ item.name }}</div>
        <div class="item-r"><img :src="item.img" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getHashParam } from "@/utils/utils.js";
export default {
  name: "healthHouse-menu",
  data() {
    return {
      oData: [
        {
          name: "爱康国宾",
          url: "https://ee.ikang.com/employee/platform/lanlv/newIndex",
          img: require("./img/icon1.png"),
        },
        {
          name: "瑞慈体检",
          url: "https://bmyi.rich-healthcare.com/html/ResvAttachWechat/index.html",
          img: require("./img/icon2.png"),
        },
        {
          name: "美年健康",
          url: "https://empapp.meinian365.com/login?code=3046432397611812",
          img: require("./img/icon.png"),
        },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {
    toDetail(item) {
      window.location.href = item.url;
    },
  },
};
</script>

<style scoped lang="less">
.healthHouse-menu {
  min-height: 100%;
  box-sizing: border-box;
  position: relative;
  .healthHouse-menu-t {
    width: 100%;
    // height:300px;
    img {
      width: 100%;
    }
  }
  .healthHouse-menu-b {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px;
    box-sizing: border-box;
    .item {
      width: 340px;
      flex-shrink: 1;
      height: 150px;
      background: #fff;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-radius: 20px;
      margin-bottom: 20px;
      .item-l {
        font-size: 30px;
      }
      .item-r {
        width: 60px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
